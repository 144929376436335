import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Layout from "../templates/layout";
import ProductHeader from "../components/Products/Header";
import Lists from "../components/Products/Lists";

import "../assets/scss/products.scss";

const Products = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{`${t("meta.products.title")} | ${t("meta.title")}`}</title>
        <meta content={t("meta.products.keyword")} name="keywords" />
        <meta content={t("meta.products.desc")} name="description" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <Layout currentLink="products" description={t("meta.products.desc")}>
        <>
          <ProductHeader
            title={t("menu.products")}
            customTitle={t("products.title")}
            isTitleH1
          />
          <Lists />
        </>
      </Layout>
    </>
  );
};

export default Products;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
